

import Axios from "axios";
import {API_URL_ONLINE} from "../config";

class AuthService {

    isLogin(){
        //return sessionStorage.getItem("username")===null?false:true;
        return true;
    }

    saveUser(username,token,code){
        sessionStorage.setItem("username",username);
        sessionStorage.setItem("token",this.createBasicAuthJwtToken(token));
        sessionStorage.setItem("code",code);
        this.axiosJwtInterceptor(this.createBasicAuthJwtToken(token))
    }

    onLogout(){
        sessionStorage.removeItem("username");
    }

    createBasicAuthJwtToken(token){
        return "Bearer "+token;
    }

    runJwtAuthentication(username,password){
        return Axios.post(`${API_URL_ONLINE}/api/login_check`,{username:username, password:password}
        );
    }

    axiosJwtInterceptor(basicAuthJwtHeader){
        
        Axios.interceptors.request.use((config)=>{
            if(this.isLogin){
                config.headers.authorization = basicAuthJwtHeader
            }

            return config
        })
    }
}

export default new AuthService();
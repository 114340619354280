import React,{Component} from 'react'
import AuthService from '../service/authService';
import {isMobile} from "react-device-detect";
import {Form,Button, Row,Card, Col,Image,Spinner} from 'react-bootstrap';

class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            username: "",
            password: "",
            showLoginSuccess: false,
            showLoginFailed: false,
            showNetworkFaild: false,
            showimage: true
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        //AuthService.runJwtAuthentication("admin","cutiwaadmin");
        //AuthService.runJwtAuthentication("d.herve","herve@2020");       
    }


    componentDidMount(){
        if(!isMobile){
            this.setState({
                showimage: false
            })
        }
    }

    render() {
        return (
            <div className="Login">
                
                <ShowCredentialMessage showLoginSuccess={this.state.showLoginSuccess} ></ShowCredentialMessage>
                <ShowCredentialMessage showNetworkFaild={this.state.showNetworkFaild} ></ShowCredentialMessage>
                <div className="container">
                    <Card style={{ fontSize: 24 }}>
                        <Card.Body>
                        <Row>
                            <Col sm={6} hidden={this.state.showimage}>
                                <Image src="http://prod.tcce-go.com/assets/images/hippo_small.jpg" rounded fluid/>
                            </Col>
                            <Col sm={6} >
                                <Row>
                                    <Col sm={12} >
                                        <Image src="http://prod.tcce-go.com/assets/images/logo_clementino.png" style={{width: 250}} rounded fluid/>
                                    </Col>
                                    <Col sm={12} style={{ textAlign: "left", fontSize:20,color:"gray",borderBottomColor: "gray"}}>
                                        ClemenTino | Connexion
                                    </Col>
                                    <Col sm={12} style={{ textAlign: "left"}} >

                                        <Form onSubmit={this.handleSubmit} >
                                        
                                            <Form.Group as ={Col}  controlId="codeid" style={{ marginLeft: -15 }}>
                                                <Form.Control type="text" placeholder="Nom utilisateur" name ="username" value={this.state.username} onChange={this.handleChange}/>
                                            </Form.Group>
                                            
                                    
                                            <Form.Group as ={Col}  controlId="codeid" style={{ marginLeft: -15 }}>
                                                <Form.Control type="password" placeholder="Mot de passe" name ="password" value={this.state.password} onChange={this.handleChange}/>
                                                <Form.Text className="text-muted" >
                                                    {this.state.showLoginFailed && <div className="alert alert-warning">nom d'utilisateur / mot de passe incorrecte</div>}
                                                </Form.Text>
                                            </Form.Group>
                                                    
                                            
                                            <Form.Group  controlId="pers.part5" >
                                                <Button type="submit" style={{
                                                        backgroundColor:"green",
                                                    }}>
                                                    Valider
                                                </Button>
                                                <Spinner animation="border" role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </Spinner>
                                            </Form.Group>
                                        </Form>
                                    </Col>
                                </Row>
                                
                            </Col>
                        </Row>
                            
                        </Card.Body>
                    

                    </Card>
                    
                </div>
                
            </div>
        );
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleSubmit(event) {
        event.preventDefault(); 
        
        AuthService.runJwtAuthentication(this.state.username,this.state.password).then((resp)=>{
            console.log(resp.data);
            if(resp.data.token){
                let coursiserCode = resp.data.user.coursier ? resp.data.user.coursier : '';
                AuthService.saveUser(this.state.username,resp.data.token,coursiserCode);
                this.props.history.push(`/`);
            }else{
                this.setState({ showLoginFailed: true, showLoginSuccess: false });
            }
            
        }).catch((error)=>{
            console.log("icic erreur");
            console.log(error);
            this.setState({ showNetworkFaild: true, showLoginSuccess: false,showLoginFailed: false, });
        })            
    }

}

function ShowCredentialMessage(props) {
    if (props.showLoginFailed) return <div>nom d'utilisateur / mot de passe incorrecte</div>;
    if (props.showLoginSuccess) return <div>login success</div>;
    if (props.showNetworkFaild) return <div style={{color:"red"}}>Probleme de connexion internet</div>;
    return null;
}

export default Login

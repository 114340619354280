import React from 'react';
//import logo from './logo.svg';
import './App.css';
import Saisi from "./component/Saisi";
import SaisiPreEnregistrement from "./component/SaisiPreEnregistrement";
import ListLivraisons from "./component/ListLivraisons";
import Tracking from "./component/Tracking";
import Login from "./component/Login";
import CoursierNoMenu from "./CoursierNoMenu"
import AuthRouter from "./component/authRouter"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./bootstrap.css"
import "./myStyle/mainStyle.css"

function App() {
  return (
    <div className="App">
      <Router>
          <Switch>
              <AuthRouter path="/" exact component={SaisiPreEnregistrement}/>
              <CoursierNoMenu path="/login" exact component={Login}/>
              <CoursierNoMenu path="/logout" exact component={Login}/>
              <AuthRouter path="/saisi" exact component={Saisi}/>
              <AuthRouter path="/saisi-pre" exact component={SaisiPreEnregistrement}/>
              <AuthRouter path="/livraisons" exact component={ListLivraisons}/>
              <AuthRouter path="/tracking" exact component={Tracking}/>
              <AuthRouter path="/elevements" exact component={ListLivraisons}/>
          </Switch>
      </Router>
    </div>
  );
}

export default App;


var date = new Date();


export const API_URL_ONLINE= "https://training.tcce-go.com";
//export const API_URL_ONLINE= "http://tcce-clementino.local/app_dev.php";
//export const API_URL_ONLINE= "http://tcce-clementino.local";
export const x_auth_token = "api12345-449c-4e49-87ff-82db2da75715";

export const startDate = date.getDay()+"/"+date.getMonth()+"/"+date.getFullYear();
export const endDate = date.getDay()+"/"+date.getMonth()+"/"+date.getFullYear();
